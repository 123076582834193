import NarendraRangani from '../assets/images/member_images/narendra_rangani.jpeg'
import VikramRangani from '../assets/images/member_images/vikram_rangani_cropped.jpeg'
import DineshSenghani from '../assets/images/member_images/dinesh_senghani_cropped.jpg'
import KashyapRangani from '../assets/images/member_images/kashyap_rangani.jpeg'

export const getManagers = () => [
  {
    name: 'Narendra Rangani',
    details:
      'Narendra Rangani(Patel) is the son of Karsan Patel. Narendra Rangani(Patel) took over his father ‘s company long back from 2002.Since 2002 he is handling and developed his father business next to impossible level .Before taking over his father company Mr. Narendra Rangani(Patel) was working with hiranandani group over 15 years and he was head incharge of overall powai. Narendra Rangani(Patel) aged 50 and His job is to look over all execution work and completing work before said periods.',
    image: NarendraRangani
  },
  {
    name: 'Dinesh Patel',
    details:
      "Dinesh Senghani is the son of a Hirji Senghani. Dinesh Senghani take over his father's company from 1995. since 1995 to till date he is handling company on behalf of Hirji Senghani. He established the business from small labour contractor to material contractor and now a developer. Dinesh Senghani aged 49 years, His job profiles include Co-ordinating between clinent, monitoring the project, Planning of various work activities of project.",
    image: DineshSenghani
  },
  {
    name: 'Vikram Rangani',
    details:
      'A self-driven visionary leader, an entrepreneur, with over 15 years of hands on experience in the Real Estate has developed a strong network of developers. His quest to remain differentiated has compelled him to enroll and complete the Real estate Management Program. His creativity and passion enables him to set a vision beyond the conventional horizons of natural progression.',
    image: VikramRangani
  },
  {
    name: 'Kashyap Rangani',
    details:
      'Co-ordinating between clients and various consulting agencies,execution agencies,supplier etc for the efficient excution of project. Assiting in preprations of various tenders in coordination with respective consulting agencies. Checking the certified bills of various agencies against the tendered boq & release payment. Supervising day to day executions of work so as to get the worj executed as per given spciffications & maintaining the quality standards.',
    image: KashyapRangani
  }
]

import React from 'react'
// import NotFound from './pages/NotFound'
import { useState } from 'react'
import BackgroundVideo from './components/BackgroundVideo'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import ThemeSwitcher from './components/ThemeSwitcher'
import { Main } from './pages/Main'

function App() {
  const [activePage, setActivePage] = useState('home')
  return (
    <>
      {/* <NotFound /> */}
      <ThemeSwitcher />
      <Navbar activePage={activePage} setActivePage={setActivePage} />
      <BackgroundVideo />
      <Main setActivePage={setActivePage} />
      <Footer />
    </>
  )
}

export default App
